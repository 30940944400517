<template>
<div class="sign-in" id="signin">
    <div class="signIn_block">
        <div class="signIn_block-content">
            <div class="left_block">
                <img class="leftImg" src="./img/chatu.png" alt="">
            </div>
            <div class="operation">
                <el-form ref="form" :model="form" :rules="formRules">
                    <el-form-item class="title">
                        <h3>鼎通NB-IoT设备管理平台</h3>
                    </el-form-item>
                    <el-form-item class="login_input" prop='username'>
                        <div class="login-item" ref="username">
                            <img class="icon_account" src="./img/account.png" alt="">
                            <el-input v-model="form.username" maxlength="11" placeholder="账号" @blur="inputBlur('username')"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item class="login_input password" prop='password'>
                        <div class="login-item" ref="password">
                            <img class="icon_pwd" src="./img/pwd.png" alt="">
                            <el-input v-model="form.password" type="password" placeholder="密码" @blur="inputBlur('password')"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item style="text-align:center;">
                        <el-button class="el-button" :loading="loginloading" type="primary" @click="onSubmit">登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
    <div class="sign_bottom">
        © 2022 珠海鼎通科技有限公司&nbsp;&nbsp;<a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备13010794号</a>
    </div>
    <!-- <div class="sign-in_bac">
        <img class="bac" src="./img/bg.png" alt="">
    </div> -->
</div>
</template>

<script>
export default {
    name: 'signIn',
    data() {
        return {
            token: '',
            form: {
                //账号
                username: "",
                //验证码
                password: ""
            },
            formRules: {
                username: [{
                    required: true,
                    message: '账号不能为空！',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: '密码不能为空！',
                    trigger: 'blur'
                }]
            },
            codeState: true,
            loginloading: false
        }
    },
    created() {
        sessionStorage.clear()
        let that = this
        document.onkeydown = (e) => {
            let key = window.event.keyCode
            if (key === 13) {
                that.onSubmit()
            }
        }
    },
    mounted() {
        document.getElementById('signin').style.height = document.body.clientHeight + 'px'
    },
    methods: {
        //提交
        onSubmit() {
            let that = this
            that.$refs.form.validate(valid => {
                // 都非空
                if (valid) {
                    this.loginloading = true
                    let params = {
                        userName: that.form.username,
                        password: that.form.password
                    }
                    this.$api.adminLogin(params).then(res => {
                        console.log('login', res)
                        if (res.code == 0) {
                            if (res.data.operatorType !== 0) {
                                sessionStorage.setItem('accountInfo', JSON.stringify(res.data))
                                sessionStorage.setItem('token', res.data.token)
                                this.$router.push({
                                    path: '/'
                                })
                            } else {
                                this.loginloading = false
                                this.$message.error('该账号暂无权限登录本平台');
                            }
                        } else {
                            this.loginloading = false
                            this.$message.error(res.msg);
                        }
                    }).catch(err => {
                        this.loginloading = false
                        this.$message.error(err);
                    })
                } else {
                    that.$refs.username.style.borderBottom = '1px solid #F56C6C'
                    that.$refs.password.style.borderBottom = '1px solid #F56C6C'
                }
            })
        },
        inputBlur(type) {
            if (this.form[type] == '') {
                this.$refs[type].style.borderBottom = '1px solid #F56C6C'
            } else {
                this.$refs[type].style.borderBottom = '1px solid #d8d8d8'
            }
        }
    }
}
</script>

<style>
/* SignIn.vue 样式 */
@import url('./css/SignIn.css');
/* SignIn.vue End */
</style>
